<template>
  <div>

    <carousal-content page="services" :contents="contents"/>

    <!-- ==== shipping modes section start ==== -->
    <section
      class="shipping section__space bg__img"
    >
      <div class="container">
        <div class="shipping__area">
          <div class="section__header section__header__space">
            <hr />
            <h2 class="primary wow animate__animated animate__fadeInUp">
              India to Singapore Shipping Modes
            </h2>
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-4">
              <div class="shipping__methods ship__img__alt ship__img__alt__th">
                <img
                  src="@/assets/images/shipping/illustration.png"
                  alt="Shipping Illustration"
                  class="cta__space"
                />
                <div class="shipping__methods__inner">
                  <h4
                    class="
                      content__space
                      wow
                      animate__animated animate__fadeInUp
                    "
                  >
                    Economy
                  </h4>
                  <div
                    class="
                      d-flex
                      align-items-center
                      content__space--extra--small
                      wow
                      animate__animated animate__fadeInUp
                    "
                  >
                    <img src="@/assets/images/shipping/check.png" alt="Check" />
                    <p class="secondary"><span>9-12</span> (Working Days)</p>
                  </div>
                  <div
                    class="
                      d-flex
                      align-items-center
                      wow
                      animate__animated animate__fadeInUp
                    "
                  >
                    <img src="@/assets/images/shipping/check.png" alt="Check" />
                    <p class="secondary last">Actual Weight</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="shipping__methods">
                <img
                  src="@/assets/images/shipping/illustration.png"
                  alt="Shipping Illustration"
                  class="cta__space"
                />
                <div class="shipping__methods__inner">
                  <h4
                    class="
                      content__space
                      wow
                      animate__animated animate__fadeInUp
                    "
                  >
                    Standard
                  </h4>
                  <div
                    class="
                      d-flex
                      align-items-center
                      content__space--extra--small
                      wow
                      animate__animated animate__fadeInUp
                    "
                  >
                    <img src="@/assets/images/shipping/check.png" alt="Check" />
                    <p class="secondary"><span>4-6</span> (Working Days)</p>
                  </div>
                  <div
                    class="
                      d-flex
                      align-items-center
                      wow
                      animate__animated animate__fadeInUp
                    "
                  >
                    <img src="@/assets/images/shipping/check.png" alt="Check" />
                    <p class="secondary last">
                      Actual Weight or Volumetric Weight*
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="shipping__methods last__methods last__alt">
                <img
                  src="@/assets/images/shipping/illustration.png"
                  alt="Shipping Illustration"
                  class="cta__space"
                />
                <div class="shipping__methods__inner">
                  <h4
                    class="
                      content__space
                      wow
                      animate__animated animate__fadeInUp
                    "
                  >
                    Economy
                  </h4>
                  <div
                    class="
                      d-flex
                      align-items-center
                      content__space--extra--small
                      wow
                      animate__animated animate__fadeInUp
                    "
                  >
                    <img src="@/assets/images/shipping/check.png" alt="Check" />
                    <p class="secondary"><span>9-12</span> (Working Days)</p>
                  </div>
                  <div
                    class="
                      d-flex
                      align-items-center
                      wow
                      animate__animated animate__fadeInUp
                    "
                  >
                    <img src="@/assets/images/shipping/check.png" alt="Check" />
                    <p class="secondary last">Actual Weight</p>
                  </div>
                </div>
                <div class="coming">
                  <h6
                    class="
                      text-center
                      content__space--small
                      wow
                      animate__animated animate__fadeInUp
                    "
                  >
                    Sea Freight Option
                  </h6>
                  <h3
                    class="text-center wow animate__animated animate__fadeInUp"
                  >
                    Coming Soon
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="shipping__animation">
        <div class="shipping__animation">
            <img src="@/assets/images/shipping/ellipse-small.png" alt="Ellipse" class="ellipse--small" />
        </div>
      </div>
    </section>
    <!-- ==== #shipping modes section end ==== -->

    <!-- ==== what section start ==== -->
    <section class="what section__space">
      <div class="container">
        <div class="what__area">
          <div class="section__header section__header__space">
            <hr />
            <h2 class="primary wow animate__animated animate__fadeInUp">
              What Is?
            </h2>
          </div>
          <div class="row d-flex align-items-center">
            <div class="col-lg-6">
              <div
                class="what__content wow animate__animated animate__fadeInUp"
              >
                <img src="@/assets/images/icons/kg.png" alt="Weight" />
                <div>
                  <h4 class="content__space--small">Actual Weight</h4>
                  <p class="tertiary">= Parcel Weight</p>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div
                class="what__content wow animate__animated animate__fadeInUp"
                data-wow-delay="0.2s"
              >
                <img src="@/assets/images/icons/kg.png" alt="Weight" />
                <div>
                  <h4 class="content__space--small">Volumetric Weight</h4>
                  <p class="tertiary und content__space--extra--small">
                    = length x width x height
                  </p>
                  <p class="tertiary text-center">5000</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ==== #what section end ==== -->

    <!-- ==== shipping rates section start ==== -->
    <section class="shipping__rate section__space">
      <div class="container">
        <div class="section__header section__header__space">
          <hr />
          <h2 class="primary wow animate__animated animate__fadeInUp">
            Standard Shipping Rates
          </h2>
        </div>
        <div class="shipping__rate__area section__header__space">
          <div class="row">
            <div class="col-xl-1">
              <img src="@/assets/images/icons/plane.png" alt="Plane" />
            </div>
            <div class="col-xl-11">
              <div class="rate__content">
                <h3
                  class="
                    section__header__space
                    wow
                    animate__animated animate__fadeInUp
                  "
                >
                  By Air
                </h3>
                <div
                  class="
                    d-flex
                    align-items-center
                    justify-content-between
                    fee__list
                    wow
                    animate__animated animate__fadeInUp
                  "
                >
                  <p class="secondary font-weight-500">Base Charge</p>
                  <p class="secondary font-weight-500">S$13.40</p>
                </div>
                <div
                  class="
                    d-flex
                    align-items-center
                    justify-content-between
                    fee__list
                    wow
                    animate__animated animate__fadeInUp
                  "
                >
                  <p class="secondary font-weight-500">Per 100g</p>
                  <p class="secondary font-weight-500">S$0.38</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p
          class="
            secondary
            text-center
            ship__quote
            wow
            animate__animated animate__fadeInUp
          "
        >
          *All goods imported via ShipIndia sea freight are not granted GST relief.
          Should you need more information on GST, please visit Singapore
          Customs’ website. For more information, click here.
        </p>
      </div>
    </section>
    <!-- ==== #shipping rates section end ==== -->

    <!-- ==== shipping cost section start ==== -->
    <section class="cost section__space__bottom">
      <div class="container">
        <div class="cost__area">
          <h5
            class="
              text-center
              cta__space
              wow
              animate__animated animate__fadeInUp
            "
          >
            Cost of <span>Land Shipping 4 individual parcels</span> from the
            India retailer:
          </h5>
          <div class="table__wrapper cta__space">
            <div class="table__responsive">
              <table class="table">
                <thead class="wow animate__animated animate__fadeInUp">
                  <tr>
                    <th>Individual</th>
                    <th>Parcel No.</th>
                    <th>Example Product</th>
                    <th>Weight</th>
                    <th>Base Shipping Rate(SGD</th>
                    <th>Weight Rate(SGD)</th>
                    <th>Total(SGD)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="wow animate__animated animate__fadeInUp">
                    <td>Invoice 1</td>
                    <td>Parcel 1</td>
                    <td>Book</td>
                    <td>0.5kg</td>
                    <td>S$8.00</td>
                    <td>S$1.90</td>
                    <td>S$9.90</td>
                  </tr>
                  <tr class="wow animate__animated animate__fadeInUp">
                    <td>Invoice 2</td>
                    <td>Parcel 2</td>
                    <td>Pair of Women's Shoes</td>
                    <td>1.5kg</td>
                    <td>S$8.00</td>
                    <td>S$5.70</td>
                    <td>S$13.70</td>
                  </tr>
                  <tr class="wow animate__animated animate__fadeInUp">
                    <td>Invoice 3</td>
                    <td>Parcel 3</td>
                    <td>Women's Handbag</td>
                    <td>2.0kg</td>
                    <td>S$8.00</td>
                    <td>S$7.60</td>
                    <td>S$15.60</td>
                  </tr>
                  <tr class="wow animate__animated animate__fadeInUp">
                    <td>Invoice 4</td>
                    <td>Parcel 4</td>
                    <td>Sheeping Bag</td>
                    <td>3.0kg</td>
                    <td>S$8.00</td>
                    <td>S$11.40</td>
                    <td>S$19.40</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <p class="text-center wow animate__animated animate__fadeInUp">
            *Examples shown are by estimation of size and weight. Actual size
            and weight may vary by merchant's packing process.
          </p>
        </div>
      </div>
    </section>
    <section class="cost section__space__bottom">
      <div class="container">
        <div class="cost__area">
          <h5
            class="
              text-center
              cta__space
              wow
              animate__animated animate__fadeInUp
            "
          >
            Cost of
            <span>Land Shipping 4 consolidated parcels into 1 invoice</span>
            from the India retailer:
          </h5>
          <div class="table__wrapper india_retailer rcta__space">
            <div class="table__responsive">
              <table class="table table__alt">
                <thead class="wow animate__animated animate__fadeInUp">
                  <tr>
                    <th>Consolidated</th>
                    <th>Parcel No.</th>
                    <th>Example Product</th>
                    <th>Weight</th>
                    <th>Base Shipping Rate(SGD</th>
                    <th>Weight Rate(SGD)</th>
                    <th>Total(SGD)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="wow animate__animated animate__fadeInUp">
                    <td rowspan="4">Invoice 1</td>
                    <td>Parcel 1</td>
                    <td>Book</td>
                    <td>0.5kg</td>
                    <td>S$8.00</td>
                    <td>S$1.90</td>
                    <td>S$9.90</td>
                  </tr>
                  <tr class="wow animate__animated animate__fadeInUp">
                    <td class="ml-0">Parcel 2</td>
                    <td>Pair of Women's Shoes</td>
                    <td>1.5kg</td>
                    <td>S$0.00</td>
                    <td>S$5.70</td>
                    <td>S$5.70</td>
                  </tr>
                  <tr class="wow animate__animated animate__fadeInUp">
                    <td class="ml-0">Parcel 3</td>
                    <td>Women's Handbag</td>
                    <td>2.0kg</td>
                    <td>S$0.00</td>
                    <td>S$7.60</td>
                    <td>S$7.60</td>
                  </tr>
                  <tr class="wow animate__animated animate__fadeInUp">
                    <td class="ml-0">Parcel 4</td>
                    <td>Sheeping Bag</td>
                    <td>3.0kg</td>
                    <td>S$0.00</td>
                    <td>S$11.40</td>
                    <td>S$11.40</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="
                d-flex
                fle-alt
                align-items-center
                justify-content-between
                wow
                animate__animated animate__fadeInUp
              "
            >
              <p>Total Cost (SGD):</p>
              <p>S$34.60</p>
            </div>
            <div
              class="
                d-flex
                align-items-center
                justify-content-between
                wow
                animate__animated animate__fadeInUp
              "
            >
              <p>Total Saving (SGD):</p>
              <p>S$24.00</p>
            </div>
          </div>
          <p class="text-center wow animate__animated animate__fadeInUp">
            *Examples shown are by estimation of size and weight. Actual size
            and weight may vary by merchant's packing process.
          </p>
        </div>
      </div>
    </section>
    <!-- ==== shipping cost section end ==== -->

    <!-- ==== shipping rate two section start ==== -->
    <section class="section__space__bottom rate__two">
      <div class="container">
        <div class="rate__two__area">
          <div class="section__header section__space__bottom">
            <hr />
            <h2 class="wow animate__animated animate__fadeInUp">
              Singapore Shipping Rates
            </h2>
          </div>
          <div class="row section__header__space">
            <div class="col-md-6 col-lg-4 column__space">
              <div
                class="
                  rate__two__package
                  wow
                  animate__animated animate__fadeInUp
                "
              >
                <h6 class="text-center">
                  Use <span>Standard</span> For Quicker Delivery
                </h6>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 column__space">
              <div
                class="
                  rate__two__package
                  wow
                  animate__animated animate__fadeInUp
                "
                data-wow-delay="0.1s"
              >
                <h6 class="text-center">
                  Use <span>Economy</span> For Cheaper Delivery
                </h6>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div
                class="
                  rate__two__package
                  wow
                  animate__animated animate__fadeInUp
                "
                data-wow-delay="0.3s"
              >
                <h6 class="text-center">
                  Use <span>Sea</span> For Bulky Items Delivery
                </h6>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-4 col-xxl-3 alt__space">
              <div
                class="rate__two__item wow animate__animated animate__fadeInUp"
              >
                <img
                  src="@/assets/images/icons/one.png"
                  alt="Save More"
                  class="section__header__space"
                />
                <h5 class="content__space">Save More</h5>
                <p>
                  Proactively integrate top-line communities before scalable
                  pontificate compelling services after diverse services....
                </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xxl-3 alt__space">
              <div
                class="rate__two__item wow animate__animated animate__fadeInUp"
                data-wow-delay="0.1s"
              >
                <img
                  src="@/assets/images/icons/three.png"
                  alt="Save More"
                  class="section__header__space"
                />
                <h5 class="content__space">Multiple Delivery Options</h5>
                <p>
                  Proactively integrate top-line communities before scalable
                  pontificate compelling s...
                </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xxl-3 alt__space">
              <div
                class="rate__two__item wow animate__animated animate__fadeInUp"
                data-wow-delay="0.2s"
              >
                <img
                  src="@/assets/images/icons/four.png"
                  alt="Save More"
                  class="section__header__space"
                />
                <h5 class="content__space">SgCare</h5>
                <p>
                  Proactively integrate top-line communities before scalable
                  pontificate compelling services after diverse services....
                </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xxl-3 alt__space">
              <div
                class="rate__two__item wow animate__animated animate__fadeInUp"
                data-wow-delay="0.3s"
              >
                <img
                  src="@/assets/images/icons/two.png"
                  alt="Save More"
                  class="section__header__space"
                />
                <h5 class="content__space">Fully Transparent Cost</h5>
                <p>
                  Proactively integrate top-line communities before scalable
                  pontificate compelling services after diverse services....
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ==== #shipping rate two section end ==== -->

    <!-- ==== sign up section start ==== -->
    <section class="sign__up">
      <div class="container">
        <div class="sign__up__area">
          <div class="row d-flex align-items-center">
            <div class="col-lg-8">
              <div class="sign__up__content column__space">
                <h2
                  class="primary wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.4s"
                >
                  Sign up now to enjoy <br />
                  10% off your first shipment!
                </h2>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="sign__up__cta text-start text-lg-end">
                <a href="#" class="button button--primary button--secondary"
                  >Sign Up Now</a
                >
              </div>
            </div>
          </div>
          <img
            src="@/assets/images/sign-up/plane.png"
            alt="Flying Plane"
            class="flying-plane"
          />
          <img src="@/assets/images/sign-up/leaf.png" alt="Leaf" class="leaf" />
        </div>
      </div>
    </section>
    <!-- ==== #sign up section end ==== -->
  </div>
</template>

<script>
import { WOW } from "wowjs"
import CarousalContent from "../components/CarousalContent.vue";
export default {
  components: { CarousalContent },
  mounted() {
    new WOW().init();
  },
   data() {
    return {
      contents: [
        {
          heading: "Shop in India, Ship to Singapore",
          text: "No more excess baggage cost. No more wondering how to get products thats based in india only.",
        },
        {
          heading: "Shop in India, Ship to Singapore",
          text: "No more excess baggage cost. No more wondering how to get products thats based in india only.",
        },
        {
          heading: "Shop in India, Ship to Singapore",
          text: "No more excess baggage cost. No more wondering how to get products thats based in india only.",
        }

      ],
    };
  },
};
</script>

<style>
</style>
